import gsap from "gsap";

const sideControl = () => {
	const sideControl = document.querySelector(".side-control");

	if (sideControl) {
		const btnTop = document.querySelector(".side-control__button-top");

		document.addEventListener("scroll", () => {
			if (window.pageYOffset > 200) {
                sideControl.classList.add("show");
                } else {
                sideControl.classList.remove("show");
			}
		});

		btnTop.addEventListener("click", () => {
			gsap.to(window, {
				duration: 1,
				scrollTo: {
					y: 0,
				},
			});
		});
	}
};

export default sideControl;
