import gsap from "gsap";

const logoBar = () => {
	const logo = document.querySelector(".logo");

	if (logo) {
		document.addEventListener("scroll", () => {
			if (window.pageYOffset > 200) {
				logo.classList.add("hide");
			} else {
				logo.classList.remove("hide");
			}
		});
	}
};

export default logoBar;
